import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import JobsPage from '../templates/Jobs';

const host = process.env.GATSBY_JOB_REQUEST;

const jobs = {
  header: {
    title: 'jobsTitle',
    subTitle: 'jobsSubTitle',
    image: 'superW.png',
  },
  mw: {
    title: 'jobsMwTitle',
    list: [
      {
        title: 'jobsMwAmbitiousTitle',
        subTitle: 'jobsMwAmbitiousSubTitle',
      },
      {
        title: 'jobsMwInterestingTitle',
        subTitle: 'jobsMwInterestingSubTitle',
      },
    ]
  },
  jobsList: {
    title: 'jobsListTitle',
    subTitle: 'jobsListSubTitle',
    lastItem: 'jobsListLastItem',
    list: [
      {
        title: 'UI/UX Designer',
        subTitle: 'Whole world',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/1y42Xz45O2QKkVpiFt_qEgpmeYAJKPub43-MbYGvpeEg/edit?usp=drive_link'
        }
      },
      {
        title: 'Corezoid developer_JD',
        subTitle: 'Whole world',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/1nIz0zPvMc-x97Lc7HxNVe3o1Cim6zTT3WA-3XTsMoh8/edit?usp=drive_link'
        }
      },
      {
        title: 'QA Engineer',
        subTitle: 'Whole world',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/19RJm5IPcrQaHnLbZeGB0sHeuZYHCjEroozBY55DaKjw/edit?usp=drive_link'
        }
      },
      {
        title: 'Support Engineer',
        subTitle: 'Whole world',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/1mocuHoUCxnR_LL8XNFSlPgPQ-5cST4Ppr-cZIOlV5ug/edit?usp=drive_link'
        }
      },
      {
        title: 'Developer (js/go/python/erlang)',
        subTitle: '',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/17o0GGOXe-S7gOK7PsoC8LBuPtFUDXRYbJtDq4TqgqPM/edit?usp=drive_link'
        }
      },
      {
        title: 'Personal Assistant to the CEO',
        subTitle: 'Dnipro',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/1HC5hi1rEXw5XJuJFcVWVNCRvXXlV0crhmc3AsmqShSk/edit?usp=drive_link'
        }
      },
      {
        title: 'Front-end developer (JS/React)',
        subTitle: 'Whole world',
        button: {
          info: 'jobsListButtonInfo',
          text: 'jobsListButton',
          link: 'https://docs.google.com/document/d/1Z5zw7QQc87kztx9oZxeC_FT7Jxvv7XmZqFw6tmbQU2M/edit?usp=drive_link'
        }
      },
    ]
  },
  courses: {
    title: 'jobsCoursesTitle',
    subTitle: 'jobsCoursesSubTitle',
    button1: {
      text: 'jobsCoursesButton1',
      link: '/learn',
    },
    button2: {
      text: 'jobsCoursesButton2',
      link: '/certification',
    },
  },
  form: {
    title: 'formJoinYourTeam',
    type: 'modal',
    fields: [
      {
        type: 'fullName',
        label: 'formFullName',
        value: ''
      },
      {
        type: 'position',
        label: 'formPosition',
        value: 'Project Manager',
        list: [
          {
            value: 'HRD',
            type: 'untech'
          },
          {
            value: 'UI/UX Designer',
            type: 'untech'
          },
          {
            value: 'Cable manager',
            type: 'untech'
          },
          {
            value: 'Developer of quantum algorithms',
            type: 'tech'
          },
          {
            value: '3D Modeller',
            type: 'tech'
          },
          {
            value: 'Geographic Information Systems Specialist',
            type: 'untech'
          },
          {
            value: 'Applied mathematician',
            type: 'tech'
          },
          {
            value: 'AutoCAD Drafter',
            type: 'tech'
          },
          {
            value: 'Front-end developer (JS/React)',
            type: 'tech'
          },
          {
            value: 'Full-stack developer (Node/React)',
            type: 'tech'
          },
          {
            value: 'Middle DevOps Engineer',
            type: 'tech'
          },
          {
            value: 'Project Manager',
            type: 'untech'
          },
          {
            value: 'Erlang Developer',
            type: 'tech'
          },
          {
            value: 'Machine Learning Engineer',
            type: 'tech'
          },
          {
            value: 'GO Developer',
            type: 'tech'
          },
          {
            value: 'Economist-financier',
            type: 'untech'
          },
          {
            value: 'Other',
            type: 'untech'
          },
        ]
      },
      {
        type: 'email',
        label: 'formEmail',
        value: ''
      },
      {
        type: 'cvLink',
        label: 'formCvLink',
        value: ''
      },
      {
        type: 'message',
        label: 'formMessage',
        value: ''
      },
    ],
    button: {
      text: 'formSendVacancy',
      link: `${host}`,
    }
  }
};


const Jobs = (props) => (
  <Layout {...props}>
    <JobsPage data={jobs}/>
  </Layout>
);

export default withIntl(Jobs);
