import {
  Button,
  // ModalButtons,
  Icon,
  // Label,
  Modal
} from 'mw-style-react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import Form from '../../components/Form/Form';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { Link } from '../../i18n';
import Utils from '../../utils/utils';
import UtilsApi from '../../utils/api';
import './Jobs.scss';


class Jobs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      title: ''
    };
  }

  webhookRedirect(fields) {
    UtilsApi.redirectJobs(fields);
  }

  renderModal() {
    const { data: { form } } = this.props;
    const { title } = this.state;
    const withInitialVacancy = { ...form,
      fields: [...form.fields.map(f => (f.type === 'position' ? { ...f, value: title } : f))]};
    const requiredFields = ['cvLink'];

    return (
      <div className="jobs__modal">
        <Modal
          size={'medium'}
        >
          <div>
            <Icon className="jobs__modal__close" type={'close'} size={'medium'} onClick={() => this.hideModal()}/>
            <Form className="jobs__modal__form" data={withInitialVacancy} onSuccess={this.webhookRedirect} requiredFields={requiredFields} />
          </div>
        </Modal>
      </div>
    );
  }

  showModal(title) {
    this.setState({
      modal: true,
      title
    });
  }
  hideModal() {
    this.setState({ modal: false });
  }

  render() {
    const { intl, data } = this.props;
    const { modal } = this.state;

    return (
      <section className="jobs">
        <HeaderPage data={data.header}/>
        <div className="jobs__mw__wrapper">
          <div className="jobs__mw">
            <div className="jobs__mw__title">
              {intl.formatMessage({id: data.mw.title})}
            </div>
            <div className="grid-wrapper jobs__mw__list">
              {data.mw.list.map((item, index) => (
                <div key={index} className="col-6 jobs__mw__item">
                  <div className="jobs__mw__item__title">{intl.formatMessage({id: item.title})}</div>
                  <div className="jobs__mw__item__subTitle">{intl.formatMessage({id: item.subTitle})}</div>
                </div>
               ))}
            </div>
          </div>
        </div>
        <div className="jobs__jobsList__wrapper">
          <div className="jobs__jobsList">
            <div className="jobs__jobsList__title">
              {intl.formatMessage({id: data.jobsList.title})}
            </div>
            <div className="jobs__jobsList__subTitle">
              {intl.formatMessage({id: data.jobsList.subTitle})}
            </div>
            <div className="jobs__jobsList__list">
              {data.jobsList.list.map((item, index) => (
                <div key={index} className="jobs__jobsList__item">
                  <div className="jobs__jobsList__item__title">{item.title}</div>
                  <div className="jobs__jobsList__item__subTitle">{item.subTitle}</div>
                  <Link to={item.button.link} className="jobs__jobsList__item__button">
                    <Button
                      className={'button__secondary'}
                      label={intl.formatMessage({id: item.button.info})}
                    />
                  </Link>
                  <Button
                    className={'button__default jobs__jobsList__item__buttonDetails'}
                    label={intl.formatMessage({id: item.button.text})}
                    onClick={() => this.showModal(item.title)}
                  />
                </div>
              ))}
              <div
                role="button"
                onClick={() => this.showModal('Other')}
                className="jobs__jobsList__item jobs__jobsList__item__last"
              >
                <div className="jobs__jobsList__item__last-half">
                  <Icon type="plus" size="large" className="jobsList__item__last__icon"/>
                </div>
                <div
                  className="jobs__jobsList__item__last-half"
                  dangerouslySetInnerHTML={{
                    __html: Utils.bbCodeToHtml(intl.formatMessage({id: data.jobsList.lastItem}))
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="jobs__courses__wrapper">
          <div className="jobs__courses">
            <div className="jobs__courses__title">
              {intl.formatMessage({id: data.courses.title})}
            </div>
            <div className="jobs__courses__subTitle">
              {intl.formatMessage({id: data.courses.subTitle})}
            </div>
            <div className="jobs__courses__buttons">
              <Link to={data.courses.button1.link} className="jobs__courses__button">
                <Button
                  className={'button__default'}
                  label={intl.formatMessage({id: data.courses.button1.text})}
                />
              </Link>
              <Link to={data.courses.button2.link} className="jobs__courses__button">
                <Button
                  className={'button__secondary'}
                  label={intl.formatMessage({id: data.courses.button2.text})}
                />
              </Link>
            </div>
          </div>
        </div>
        {modal && this.renderModal()}
      </section>
    );
  }
}


Jobs.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(Jobs);
